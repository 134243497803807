import React from "react"
import { Helmet } from "react-helmet"
import Container from 'react-bootstrap/Container';

export default () => (
    <div>
        <Helmet>
        <meta charSet="utf-8" />
        <title>BrainEngine</title>

        <meta name="title" content="BrainEngine" />
        <meta name="description" content="emPOWERING the minds of tomorrow" />
        
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.brainengine.us/" />
        <meta property="og:title" content="BrainEngine" />
        <meta property="og:description" content="emPOWERING the minds of tomorrow" />
        <meta property="og:image" content="https://www.brainengine.us/og.png" />
        
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.brainengine.us/" />
        <meta property="twitter:title" content="BrainEngine" />
        <meta property="twitter:description" content="emPOWERING the minds of tomorrow" />
        <meta property="twitter:image" content="https://www.brainengine.us/og.png" />
        </Helmet>
        <Container>
        <div className="text-white text-area">
            <div>
                <h1 className="display-1">404</h1>
                <p className="lead">Page not found</p>
            </div>
        </div>
        </Container>
    </div>
)